<template lang="">
    <v-card>
    <s-crud
      :config="config"
      title="Trabajo Remoto"
      :filter="filter"
      add
      edit
      remove
      @save="save($event)"
    >
      <template #filter>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <s-date
                label="Fecha"
                clearable
                v-model="filter.RtwDate"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template scope="props">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <s-toolbar-person
                noCamera
                noEdit
                @returnPerson="returnPerson($event, props.item)"
                v-model="props.item.PrsDocumentNumber"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <s-text
                ref="textNtpName"
                label="Nombres y Apellidos Completos"
                v-model="props.item.NtpFullName"
                readonly
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="6">
              <s-select-area
                label="Área"
                v-model="props.item.AreID"
                :text="props.item.AreName"
                ref="selectArea"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <s-date label="Fecha*" v-model="props.item.RtwDate" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <s-text label="Descripción*" v-model="props.item.RtwDescription" />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </s-crud>
  </v-card>
</template>
<script>
import SText from "../../../components/Utils/SText.vue";
import _RemoteWorkService from "../../../services/HumanResource/Compensation/HmnRemoteWorkService";
import _WorkService from "../../../services/HumanResource/WorkerService";
import sToolbarPerson from "@/components/Utils/RecursosHm/sToolbarAgreementPerson";
import SSelectArea from "../../../components/Utils/SSelectArea.vue";
import SDate from "@/components/Utils/SDate";
export default {
  components: {
    SText,
    SDate,
    sToolbarPerson,
    SSelectArea,
  },
  data() {
    return {
      itemsNumberGuide: [],
      item: {},
      filter: {
        RtwDate: null,
      },
      config: {
        service: _RemoteWorkService,
        model: {
          RtwID: "ID",
          RtwDate: "date",
          RtwDescription: "string",
        },
        headers: [
          { text: "ID", value: "RtwID" },
          { text: "Trabajador", value: "NtpFullName" },
          { text: "Area", value: "AreName" },
          { text: "Día", value: "RtwDate" },
          { text: "Descripción", value: "RtwDescription" },
        ],
      },
    };
  },
  methods: {
    returnPerson(value, item) {
      if (value != null) {
        item.NtpID = value.NtpID;
        item.NtpFullName = value.NtpFullName;
        item.WkrID = value.WkrID;

        //if (item.WkrID == null || item.WkrID == 0) {
          _WorkService
            .DetailByNtpID(value.NtpID, this.$fun.getUserID())
            .then((response) => {
              this.item.WkrID = response.data.WkrID;
              this.item.AreID = response.data.AreID;
              this.$refs.selectArea.text = response.data.AreName;
            });
        //}
      }
      
    },
    EditEvent(item) {
      this.item = { ...item };
      //console.log(this.item);
      
    },
    save(item) {
      if (item.RtwID == null || item.RtwID == 0) {
        item.WkrID = this.item.WkrID;
        item.AreID = this.item.AreID;
      }
      item.save();
    },
  },
};
</script>
